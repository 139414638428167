import { deferred } from 'OK/utils/utils';

var activated = deferred(),
    notifyOnAddListeners = [];

function notifyOnAdd(added) {
    notifyOnAddListeners.forEach(function (listener) {
        listener(added);
    });
}

function activate(gwtCache) {
    activated.resolve(gwtCache);
}

function listen(onAdd, onUpdate, onRemove) {
    activated.promise.then(function (cache) {
        cache.addListener(onAdd, onUpdate, onRemove);
    });
}

function add(userId, onlineStatus) {
    activated.promise.then(function (cache) {
        var update = {};
        var info = {
            "ui": userId,
            "online": onlineStatus || 0
        };
        update[userId] = info;
        cache.updateUserCache(update);
        notifyOnAdd([info]);
    });
}

function remove(userId) {
    activated.promise.then(function (cache) {
        cache.removeRefFromCache(userId);
    });
}

function ifOnline(userId) {
    return new Promise(function (resolve, reject) {
        activated.promise.then(function (cache) {
            var user = cache.getFromUserCache(userId);
            var isOnline = user && (user.online === 1 || user.online === 4 || user.online === 5);
            if (isOnline) {
                resolve();
            } else {
                reject();
            }
        });
    });
}

function registerOnAddListener(listener) {
    notifyOnAddListeners.push(listener);
}

function unregisterOnAddListener(listener) {
    var index = notifyOnAddListeners.indexOf(listener);
    if (index > -1) {
        notifyOnAddListeners.splice(index, 1);
    }
}

export default { activate, listen, add, remove, ifOnline, registerOnAddListener, unregisterOnAddListener };

export { activate, listen, add, remove, ifOnline, registerOnAddListener, unregisterOnAddListener };
